import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';

import { CMS_POPUP } from './CmsPopup.config';

import './CmsPopup.style';

/** @namespace Pwa/Component/CmsPopup/Component */
export class CmsPopupComponent extends PureComponent {
    static propTypes = {
        cms_block: PropTypes.string.isRequired,
        isSidePopup: PropTypes.bool,
        id: PropTypes.string,
        blockType: PropTypes.string,
    };

    static defaultProps = {
        id: CMS_POPUP,
        isSidePopup: false,
        blockType: '',
    };

    render() {
        const {
            id, cms_block, isSidePopup, blockType,
        } = this.props;

        return (
            <Popup
              id={ id }
              isSidePopup={ isSidePopup }
              clickOutside
            >
                <CmsBlock identifier={ cms_block } blockType={ blockType } />
            </Popup>
        );
    }
}

export default CmsPopupComponent;
