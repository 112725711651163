import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import Loader from 'Component/Loader';
import {
    CmsBlockContainer as SourceCmsBlockContainer,
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';
import CmsBlockQuery from 'SourceQuery/CmsBlock.query';

import 'swiper/swiper.scss';

/** @namespace Pwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        ...SourceCmsBlockContainer.propTypes,
        showLoader: PropTypes.bool,
        placeHolderSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string,
        }),
    };

    static defaultProps = {
        showLoader: false,
        placeHolderSize: {
            width: '',
            height: '',
        },
    };

    state = {
        cmsBlock: {},
    };

    containerProps = () => {
        const { blockType } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType };
    };

    componentDidMount() {
        this._getCmsBlock();
    }

    shouldComponentUpdate(_nextProps, _nextState) {
        const { cmsBlock } = this.state;

        if (cmsBlock && Object.keys(cmsBlock).length > 0) {
            return false;
        }

        return true;
    }

    _getCmsBlock() {
        const { identifier } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            }
        );
    }

    render() {
        const { showLoader, placeHolderSize } = this.props;
        const { cmsBlock } = this.state;

        if (!cmsBlock.identifier && Object.keys(cmsBlock).length > 0) {
            if (placeHolderSize.width || placeHolderSize.height) {
                return <div style={ placeHolderSize } className="CmsBlock-Loader" />;
            }

            if (showLoader) {
                return <Loader isLoading />;
            }
        }

        return (
            <RenderWhenVisible>
                <CmsBlock
                  { ...this.containerProps() }
                />
            </RenderWhenVisible>
        );
    }
}

export default CmsBlockContainer;
