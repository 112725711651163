import PropTypes from 'prop-types';

import {
    CmsBlockComponent as SourceCmsBlock,
} from 'SourceComponent/CmsBlock/CmsBlock.component';
import Html from 'SourceComponent/Html';
import { ChildrenType } from 'Type/Common.type';

import './CmsBlock.override.style';

/** @namespace Pwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool,
        }),
        blockType: PropTypes.string,
        children: ChildrenType,
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        blockType: '',
    };

    shouldComponentUpdate(_nextProps) {
        const { cmsBlock } = this.props;

        if (cmsBlock && Object.keys(cmsBlock).length > 0) {
            return false;
        }

        return true;
    }

    renderPlaceholder() {
        const {
            children,
        } = this.props;

        if (children.length) {
            return children;
        }

        return null;
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled,
            },
            blockType,
        } = this.props;

        if (!content || disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              key={ identifier }
              mods={ { type: blockType } }
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlockComponent;
